import React, { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import './TextInput.css'

const InputTextBox = (props) => {
    const {
        label,
        required,
        containerClass,
        placeholder,
        type,
        visible,
        disabled,
        onChange,
        maxLength,
        hint,
        hintId,
        defvalue
    } = props
    const [tempValue, setTempValue] = useState('')
    const [inputType, setInputType] = useState("password")
    useEffect(() => {
        setTempValue(defvalue)
    }, [defvalue])
    const handleChange = (e) => {
        // e.preventDefault()
        setTempValue(e.target.value)
        try {
            onChange(e.target.value)
        }
        catch { }
    }
    const handleEye = () => {
        inputType === 'password' ? setInputType('text') : setInputType('password');
    }
    return (
        <Form.Group
            className={containerClass}
        >
            <Form.Label
                className='label-text-content'
            >
                {label && label}
                {required && ' *'}
            </Form.Label>
            {(type === 'password'&&visible)?
                <InputGroup>
                    <Form.Control
                        type={inputType}
                        value={tempValue}
                        className='input-password-control'
                        required={required ? required : false}
                        disabled={disabled ? disabled : false}
                        placeholder={placeholder || ''}
                        onChange={handleChange}
                        aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text
                        id="basic-addon1"
                        className="input-password-eye"
                        onClick={handleEye}
                        style={{ height: '2.3rem' }}
                    >
                        {inputType === 'password' ?
                            <AiOutlineEye /> :
                            <AiOutlineEyeInvisible />
                        }
                    </InputGroup.Text>
                </InputGroup> :

                <Form.Control
                    type={type?type:'text'}
                    value={tempValue}
                    className='input-text-control'
                    maxLength={maxLength?maxLength:null}
                    required={required ? required : false}
                    disabled={disabled ? disabled : false}
                    placeholder={placeholder || ''}
                    onChange={handleChange}
                    aria-describedby={hintId?hintId:null}
                />
                
            }
            {hint&&<Form.Text id={hintId} muted>
                {hint}
            </Form.Text>}
        </Form.Group>
    )
}

export default InputTextBox
