import React from 'react'
import { Form } from 'react-bootstrap'
import { Dropdown } from 'semantic-ui-react'
import './DropdownBox.css'

const DropDownBox = ({
    options,
    label,
    required,
    containerClass,
    placeholder,
    disabled,
    fluid,
    floating,
    search,
    selection,
    multiple,
    setDataValue,
    selectedValue,
    
    addNew,
    setNew
}) => {
    return (
        <Form.Group
            className={`custom-dropdown ${containerClass}`}
        >
            <Form.Label
                className='label-text-content'
            >
                {label && label}
                {required && ' *'}
            </Form.Label>
            <Dropdown
                options={options&&options}
                placeholder={placeholder || 'Select'}
                required={required?required:false}
                disabled={disabled?disabled:false}
                fluid={fluid?fluid:false}
                floating={floating?floating:false}
                search={search?search:false}
                selection={selection?selection:true}
                multiple={multiple?multiple:false}
                allowAdditions={addNew?addNew:false}
                value={selectedValue?selectedValue:''}
                onChange={(e, data)=>setDataValue(data?.value)}
                onAddItem={(e, data)=>setNew(data)}
            />
        </Form.Group>
    )
}

export default DropDownBox