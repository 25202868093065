import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import physician_add from "../../../../../assets/pictures/icons/physician-add-icon.png";
import "./PatientCard.css";
import IdFrontPreview from "./components/IdFrontPreview";
import IdBackPreview from "./components/IdBackPreview";
import { toPng } from "html-to-image";
import { FiSearch } from "react-icons/fi";
import jsPDF from "jspdf";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePatientServices from "../../../../services/api-services/patientServices";
import Swal from "sweetalert2";
import IdBackContent from "./components/IdBackContent";
import useAccountServices from "../../../../services/api-services/accountServices";
import useGetListDatas from "../../../../services/other-services/getListDatas";
import PatientSearchBox from "../../../../shared/form-components/search-box/PatientSearchBox";

const initialState = {
  search: "",
};

const PatientCard = () => {
  const [templateName, setTemplateName] = useState("");
  const [title, setTitle] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [patientName, setPatientName] = useState("##### #######");
  const [patientAge, setPatientAge] = useState("##");
  const [patientBlood, setPatientBlood] = useState("##");
  const [patientMobile, setPatientMobile] = useState("##########");
  const [patientRegistered, setPatientRegistered] = useState(new Date());
  const [patientId, setPatientId] = useState("#####/######");
  const [templateList, setTemplateList] = useState([]);

  // const { patient_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const idFront = useRef();
  const idBack = useRef();

  const { getPatientList } = usePatientServices();
  const { getCardTemplates } = useAccountServices();

  const { postPatientCardTemplate } = usePatientServices();

  const { getSemanticOptionsList } = useGetListDatas();

  useEffect(() => {
    if (location?.state?.patient_id) {
      getPatientData(location?.state?.patient_id);
    }
    getPatientCardTemplates();
  }, []);

  const getPatientCardTemplates = async () => {
    try {
      const response = await getCardTemplates("Patient");
      if (response?.success) {
        const data = response?.data;
        var tempList = [];
        data.map((item) => {
          const label = item?.name.toUpperCase();
          tempList.push({
            value: item,
            text: label,
          });
        });
        setTemplateList(tempList);
      }
    } catch {}
  };

  const getPatientData = async (id) => {
    if (id) {
      try {
        const data = {
          patient_id: id ? id : "",
        };
        const response = await getPatientList(data);
        if (response?.success) {
          const content = response.data;
          setPatientName(content.full_name);
          setPatientAge(content.age_year);
          setPatientBlood(content.blood_group);
          setPatientMobile(content.mobile);
          setPatientRegistered(new Date(content.medical_record.admission_date));
          setPatientId(content.patient_id);
        } else {
          Swal.fire("", response?.message, "error");
        }
      } catch {}
    }
  };

  const handleTemplate = (data) => {
    setTitle(data?.title ? data.title : "");
    setOption1(data?.option1 ? data.option1 : "");
    setOption2(data?.option2 ? data.option2 : "");
    setOption3(data?.option3 ? data.option3 : "");
    setOption4(data?.option4 ? data.option4 : "");
  };

  const handleTemplateSubmit = async () => {
    if (!templateName || !title) {
      Swal.fire("", "Please input Template Name and Title ! ! !", "info");
      return;
    }
    try {
      const data = JSON.stringify({
        name: templateName,
        title: title,
        option1: option1,
        option2: option2,
        option3: option3,
        option4: option4,
      });
      console.log(data);
      const response = await postPatientCardTemplate(data);
      if (response?.success) {
        getPatientCardTemplates();
        Swal.fire("", response?.message, "success");
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch {}
  };

  const generateCardImage = useCallback(async () => {
    const ImageUrl = [];
    let timerInterval;
    Swal.fire({
      title: "Card is being generated!",
      html: "Please wait for a few seconds ...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
    await toPng(idFront.current, { cacheBust: false })
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = `${patientId}-id-front.png`;
        link.href = dataUrl;
        ImageUrl.push(dataUrl);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    await toPng(idBack.current, { cacheBust: false })
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = `${patientId}-id-back.png`;
        link.href = dataUrl;
        ImageUrl.push(dataUrl);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    const doc = new jsPDF();
    doc.addImage(ImageUrl[0], "PNG", 10, 5, 85.725, 53.975, "front");
    doc.addImage(ImageUrl[1], "PNG", 110, 5, 85.725, 53.975, "back");
    doc.save(`${patientId}-patient-card`);
    if (patientId > 0) {
      navigate("/patient-card-generate");
    } else {
      window.location.reload();
    }
  }, [idFront, idBack]);

  const handleSearch = (item) => {
    setPatientName(item.full_name);
    setPatientAge(item.age_year);
    setPatientBlood(item.blood_group);
    setPatientMobile(item.mobile);
    setPatientRegistered(new Date(item.medical_record.admission_date));
    setPatientId(item.patient_id);
  };

  return (
    <div className="my-4 row mx-0">
      <h3 className="col-12 text-center">Patient Health Card</h3>
      <span className="col-8" />
      {!location?.state?.patient_id ? (
        // <span className="col-12 col-sm-8 col-md-6 col-lg-4 px-5">
        <div className="col-12 col-sm-8 col-md-6 col-lg-4 px-5">
          <PatientSearchBox
            handleData={handleSearch}
            placeholder={"Search Patient"}
            icon={
              <div className="search-icon-btn">
                <span>
                  <FiSearch className="search-icon-img" />
                </span>
              </div>
            }
          />
          {/* </span> */}
        </div>
      ) : (
        <span className="col-12" />
      )}
      <div className="card-preview-container my-3 col-12 row">
        <div className="col-6 text-center fs-5 py-2">Front</div>
        <div className="col-6 text-center fs-5 py-2">Back</div>
        <div className="card-front-preview col-6">
          <IdFrontPreview
            patient={{
              ...{
                patientName,
                patientAge,
                patientBlood,
                patientMobile,
                patientId,
                patientRegistered,
              },
            }}
            useref={idFront}
          />
        </div>
        <div className="card-back-preview col-6">
          <IdBackPreview
            patient={{
              ...{
                patientId,
              },
            }}
            useref={idBack}
            title={title}
            point1={option1}
            point2={option2}
            point3={option3}
            point4={option4}
          />
        </div>
        <div className="col-12 d-flex justify-content-center my-3">
          <div className="generate-card-btn" onClick={generateCardImage}>
            Generate Card Image
          </div>
        </div>
        <IdBackContent
          value={{
            ...{
              templateList,
              templateName,
              title,
              option1,
              option2,
              option3,
              option4,
            },
          }}
          setValue={{
            ...{
              setTemplateName,
              setTitle,
              setOption1,
              setOption2,
              setOption3,
              setOption4,
            },
          }}
          functions={{
            ...{
              handleTemplate,
              handleTemplateSubmit,
            },
          }}
        />
      </div>
    </div>
    // <div className="my-4 row mx-0">
    //   <h3 className="col-12 text-center">Patient Health Card</h3>

    //   {!location?.state?.patient_id ? (
    //     <div className="col-12 col-sm-8 col-md-6 col-lg-4 px-5">
    //       <PatientSearchBox
    //         handleData={handleSearch}
    //         placeholder={"Search Patient"}
    //         icon={
    //           <div className="search-icon-btn">
    //             <span>
    //               <FiSearch className="search-icon-img" />
    //             </span>
    //           </div>
    //         }
    //       />
    //     </div>
    //   ) : (
    //     <div className="col-12" />
    //   )}

    //   <div className="card-preview-container my-3 row col-12">
    //     <div className="col-6 col-md-4 text-center fs-5 py-2">Front</div>
    //     <div className="col-6 col-md-4 text-center fs-5 py-2">Back</div>

    //     <div className="card-front-preview col-12 col-md-6">
    //       <IdFrontPreview
    //         patient={{
    //           patientName,
    //           patientAge,
    //           patientBlood,
    //           patientMobile,
    //           patientId,
    //           patientRegistered,
    //         }}
    //         useref={idFront}
    //       />
    //     </div>

    //     <div className="card-back-preview col-12 col-md-6">
    //       <IdBackPreview
    //         patient={{ patientId }}
    //         useref={idBack}
    //         title={title}
    //         point1={option1}
    //         point2={option2}
    //         point3={option3}
    //         point4={option4}
    //       />
    //     </div>

    //     <div className="col-12 d-flex justify-content-center my-3">
    //       <div className="generate-card-btn" onClick={generateCardImage}>
    //         Generate Card Image
    //       </div>
    //     </div>

    //     <IdBackContent
    //       value={{
    //         templateList,
    //         templateName,
    //         title,
    //         option1,
    //         option2,
    //         option3,
    //         option4,
    //       }}
    //       setValue={{
    //         setTemplateName,
    //         setTitle,
    //         setOption1,
    //         setOption2,
    //         setOption3,
    //         setOption4,
    //       }}
    //       functions={{
    //         handleTemplate,
    //         handleTemplateSubmit,
    //       }}
    //     />
    //   </div>
    // </div>
  );
};

export default PatientCard;
