import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Modal } from 'react-bootstrap'
import DropDownBox from '../../../../../shared/form-components/dropdown/CustomDropDown'
import InputTextBox from '../../../../../shared/form-components/form-text/InputTextBox'
import useConsultationServices from '../../../../../services/api-services/consultationServices'
import usePhysicianServices from '../../../../../services/api-services/physicianServices'

const TokenSettings = () => {
    const [showManageModal, setShowManageModal] = useState(false)
    const [tokenConfigList, setTokenConfigList] = useState('')
    const [physicianList, setPhysicianList] = useState([])
    const [physician, setPhysician] = useState('')
    const [startFrom, setStartFrom] = useState('')
    const [prefix, setPrefix] = useState('')

    const {
        getAllTokens,
        getPhysicianToken,
        postNewToken
    } = useConsultationServices()

    const {
        getPhysicianList
    } = usePhysicianServices()

    const physicianListData = async () => {
        try {
            const response = await getPhysicianList()
            if (response.success) {
                const data = response?.data
                var templist = []
                data.map((item)=>{
                    templist.push(
                        {
                            text: item?.basic_info?.full_name,
                            value: item?.basic_info?.id,
                            description: item?.medical_id?.speciality_type
                        }
                    )
                })
                setPhysicianList(templist)
            }
        } catch { }
    }

    const handleSubmit = async() => {
        if(!physician || !startFrom || !prefix){
            Swal.fire('','Fill out all given fields','error')
        }
        try{
            const data = JSON.stringify(
                {
                    fk_physician:physician,
                    token_prefix:prefix,
                    start_from:startFrom
                }
            )
            const response = await postNewToken(data)
            if(response?.success){
                Swal.fire('', response?.message, 'success')
                getTokens()
                handleCancel()
            }
            else{
                Swal.fire('', response?.message, 'error')
            }
        }catch { }
    }

    const editTokenLoad = async() =>{
        try{
            const response = await getPhysicianToken(physician)
            if(response?.success){
                setStartFrom(response?.data?.start_from)
                setPrefix(response?.data?.token_prefix)
            }
            else{
                setStartFrom('')
                setPrefix('')
            }
        }catch{
            setStartFrom('')
            setPrefix('')
        }
    }

    const getTokens = async() => {
        try{
            const response = await getAllTokens()
            if(response?.success){
                setTokenConfigList(response?.data)
            }
            else{
                console.log(response?.errors)
            }
        }catch(err){
            setTokenConfigList([])
            console.log(err?.response?.errors)
        }
    }

    const handleManageBtn = () => {
        physicianListData()
        setShowManageModal(true)
    }

    const handleCancel = () => {
        setPhysician('')
        setStartFrom('')
        setPrefix('')
        setShowManageModal(false)
    }

    useEffect(()=>{
        getTokens()
    },[])

    useEffect(()=>{
        if(physician){
            editTokenLoad()
        }
    },[physician])

    return (
        <div>
            <Modal
                show={showManageModal}
                onHide={()=>setShowManageModal(false)}
                centered={true}
                contentClassName="custom-modal"
            >
                <Modal.Header
                    closeButton
                    className='custom-modal-header'
                >
                    Manage Token Settings
                </Modal.Header>
                <Modal.Body
                    className='custom-modal-body'
                >
                    <div className='custom-modal-content row mx-0'>
                        <div
                            className='col-12 row mx-0'
                        >
                            <DropDownBox
                                label={'ID Type'}
                                containerClass={'col-12 mb-3'}
                                fluid={true}
                                selection={true}
                                options={physicianList}
                                setDataValue={setPhysician}
                                selectedValue={physician}
                            />
                            <InputTextBox
                                label={'Prefix'}
                                containerClass={'col-6 mb-3'}
                                onChange={setPrefix}
                                defvalue={prefix}
                            />
                            <InputTextBox
                                label={'Start From'}
                                containerClass={'col-6 mb-3'}
                                onChange={setStartFrom}
                                defvalue={startFrom}
                            />
                        </div>
                        <div
                            className='col-12 row my-3'
                        >
                            <span className='col-6' />
                            <div
                                className='col-3'
                            >
                                <div 
                                    className='crop-cancel-btn'
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </div>
                            </div>
                            <div
                                className='col-3'
                            >
                                <div 
                                    className='crop-submit-btn'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </div>
                            </div>
                        </div>  
                    </div>
                </Modal.Body>
            </Modal>
            <div className="col-2 my-2">
                <div 
                    className="tabpane-extra-btns"
                    onClick={handleManageBtn}
                >
                    Manage Tokens
                </div>
            </div>
            <div className='patient-list-container'>
                <div className="patient-table-container container-white">
                    <table className='table patient-list-table'>
                        <thead className='patient-list-table-head'>
                            <tr>
                                <th>Physician Name</th>
                                <th className='text-center'>Prefix</th>
                                <th className='text-center'>Start From</th>
                                <th className='text-center'>Latest Token</th>
                            </tr>
                        </thead>
                        <tbody className='patient-list-table-body'>
                        {tokenConfigList && tokenConfigList.map((item, index) => {
                            return (
                            <tr key={index}>
                                <td>{item?.physician}</td>
                                <td className='text-center'>{item?.token_prefix}</td>
                                <td className='text-center'>{item?.start_from}</td>
                                <td className='text-center'>
                                    {item?.token}
                                </td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    )
}

export default TokenSettings
