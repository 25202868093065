import React, { useState } from 'react'
import './Login.css'
import InputTextBox from '../../shared/form-components/form-text/InputTextBox'
import useAuth from '../../hooks/useAuth'
import useAccountServices from '../../services/api-services/accountServices'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import logoandtext from '../../../assets/logos/Group 3.png'
// import logoandtext from '../../../assets/pictures/icons/svg/Group 3.svg'

const Login = () => {
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const navigate = useNavigate()
    
    const { setAuth } = useAuth()
    const { login } = useAccountServices()

    const handleSubmit = async (e) => {

        e.preventDefault()

        try {
            const data = JSON.stringify({
                username: username,
                password: password,
            });
            const response = await login(data)
            if (response?.success) {
                const accessToken = response?.data?.token;
                const role = response?.data?.fk_role;
                const image = response?.data?.image;
                const firstName = response?.data?.first_name;
                const lastName = response?.data?.last_name;
                var name;
                if (lastName){
                    name = firstName +" "+ lastName
                }
                else{
                    name = firstName
                }
                localStorage.setItem("username", username)
                localStorage.setItem("password", password)
                localStorage.setItem("accessToken", accessToken)
                localStorage.setItem("role", role)
                localStorage.setItem("profileImage", image)
                localStorage.setItem("name", name)
                setAuth({ username, password, accessToken, role, image, name })
                switch(role){
                    case 'Hospital':
                        navigate("/")
                        break
                    case 'Physician':
                        navigate("/")
                        break
                    case 'Admin':
                        navigate("/")
                        break
                }
                
            }
            else {
                Swal.fire("", "login failed", "error")
            }
        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <div className="login-container">
            <div className="row w-100 vh-100">
                <div className="col-lg-7 col-md-7 d-none d-md-block left-side rounded ms-3 my-3 px-0">
                    <div className='d-flex justify-content-center align-items-center vh-100'>
                        <img src={logoandtext} alt="Logo" className="w-50"/>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center ms-5">
                    <div className="login-box w-100 px-4 py-5">
                        <h1 className="text-start mb-5 login-head">Sign In</h1>
                        <form onSubmit={handleSubmit} className='mt-5'>
                            <div className="mb-3">
                                <input type="text" className="py-3 w-100 border rounded ps-3 form-input" placeholder="Username" value={username} required onChange={(e)=>setUsername(e.target.value)}/>
                            </div>
                            <div className=" mb-4">
                                <input type="password" className="py-3 w-100 border rounded ps-3 form-input" placeholder="Password" value={password} required onChange={(e)=>setPassword(e.target.value)}/>
                            </div>
                            <div className='d-flex justify-content-center mt-5'>
                                <button type="submit" className="btn login-button py-2 px-3">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    )
}

export default Login
